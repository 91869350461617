<template>
  <div>
    <h3 class="heading-3 mb-16 mt-24 md:mt-56">
      {{ i18n(translations.title) }}
    </h3>

    <RevCard class="mb-56">
      <div ref="errorMessageBlock" class="flex">
        <RevInfoBlock
          v-if="errorMessage"
          class="mx-20 mb-20 mt-24"
          data-test="payment-error"
          dismissable
          :icon="IconHeartExploded"
          :title="errorMessage?.title || ''"
          variant="warning"
        >
          <div class="body-2 mt-8">
            <p>{{ errorMessage?.description }}</p>
          </div>
        </RevInfoBlock>
      </div>

      <PaymentForm
        :base-price="cartStore.priceAfterDiscount"
        :create-payment="createPayment"
        :payment-methods="paymentMethods ?? []"
        :resume-payment-id="resumePaymentId"
        @setup-error="handleSetupError"
        @submit-error="handleSubmitError"
        @submit-start="handleSubmitStart"
        @submit-success="handleSubmitSuccess"
        @update:selected-method="handleMethodChange"
      />
    </RevCard>

    <DiscountCard v-if="!cartStore.bouyguesMobilePlan" class="mb-56" />

    <ReassuranceItems>
      <BouyguesReassuranceItems
        v-if="cartStore.bouyguesMobilePlan"
        :benefits="cartStore.bouyguesMobilePlan.benefits"
      />
    </ReassuranceItems>
  </div>
</template>

<script setup lang="ts">
import { navigateTo, useRoute } from '#imports'
import { computed, onMounted, onUnmounted, ref } from 'vue'

import { postOrder } from '@backmarket/http-api/src/api-specs-buyback/customer/customer'
import {
  type PaymentMethod,
  postCreate,
} from '@backmarket/http-api/src/api-specs-payment/payment'
import type { HttpApiError } from '@backmarket/http-api/src/utils/HttpApiError'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import {
  PaymentForm,
  type PaymentFormCreateFunctionOptions,
  type PaymentReadableMessage,
  type SetupErrorEvent,
  type SubmitErrorEvent,
  type SubmitStartEvent,
  type SubmitSuccessData,
  toCheckoutPaymentCreateBody,
  usePaymentRedirection,
} from '@backmarket/nuxt-module-payment/form'
import { useMarketPaymentMethodsFor } from '@backmarket/nuxt-module-payment/methods'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevCard } from '@ds/components/Card'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconHeartExploded } from '@ds/icons/IconHeartExploded'

import BouyguesReassuranceItems from '~/scopes/checkout/components/BouyguesReassuranceItems/BouyguesReassuranceItems.vue'
import { useBouyguesStore } from '~/scopes/checkout/stores/bouyguesStore'
import ReassuranceItems from '~/scopes/reassurance/components/ReassuranceItems/ReassuranceItems.vue'

import DiscountCard from '../../components/Discount/DiscountCard.vue'
import useHandleUnauthorizedUser from '../../composables/useHandleUnauthorizedUser'
import usePaymentErrors from '../../composables/usePaymentErrors'
import { CHECKOUT } from '../../routes-names'
import { useCartStore } from '../../stores/cartStore'
import { useDiscountStore } from '../../stores/discountStore'
import { useLoaderStore } from '../../stores/loaderStore'
import { useSwapStore } from '../../stores/swapStore'

import translations from './Payment.translations'
import {
  MOBILE_PLAN_ERROR,
  getMobilePlanCustomerInformation,
} from './utils/getMobilePlanCustomerInformation'

const experiments = useExperiments()

const i18n = useI18n()
const cartStore = useCartStore()
const discountStore = useDiscountStore()
const loaderStore = useLoaderStore()
const route = useRoute()
const proceedRedirection = usePaymentRedirection({
  paymentResultLocation: (paymentId) => ({
    type: 'internal',
    name: CHECKOUT.PAYMENT_RESULT,
    params: { paymentId },
  }),
})
const swapStore = useSwapStore()
const tracking = useTracking()
const bouyguesStore = useBouyguesStore()

const { handleUnauthorizedUser } = useHandleUnauthorizedUser()
const { handlePaymentErrors } = usePaymentErrors()

const errorMessage = ref<PaymentReadableMessage | null>(null)
const errorMessageBlock = ref()

const { data: paymentMethods } = await useMarketPaymentMethodsFor({
  listings: Object.keys(cartStore.availableItemsById),
  bagPrice: () => cartStore.priceAfterDiscount,
  hasInsurance: cartStore.hasInsurance,
  tokenization: cartStore.hasMonthlyInsurance,
})

const resumePaymentId = computed(() => route.query.paymentId as string)

onMounted(() => {
  loaderStore.disable()
  tracking.trackFunnel(cartStore.trackingData(CHECKOUT.PAYMENT))
})

onUnmounted(() => {
  cartStore.fetchCart()
  loaderStore.disable()
})

async function handleMobilePlanErrors(error: Error) {
  if (error.message === MOBILE_PLAN_ERROR.NO_ACCESS_TOKEN) {
    navigateTo({ name: CHECKOUT.SERVICES.BOUYGUES.SIGN_UP })
  }

  if (error.message === MOBILE_PLAN_ERROR.NO_PHONELINE_SETUP_INFORMATION) {
    navigateTo({ name: CHECKOUT.SERVICES.BOUYGUES.PHONELINE_SETUP })
  }
}

const createPayment = async (options: PaymentFormCreateFunctionOptions) => {
  try {
    const paymentResponse = await $httpFetch(postCreate, {
      body: {
        ...toCheckoutPaymentCreateBody(options),
        ...getMobilePlanCustomerInformation(
          Boolean(cartStore.bouyguesMobilePlan),
          bouyguesStore.accessToken,
          bouyguesStore.phonelineSetupInformation,
        ),
        ...(isEmpty(discountStore.paymentData)
          ? {}
          : {
              promotion_code_pk: discountStore.paymentData.id,
              amount_promotion: parseFloat(
                discountStore.paymentData.totalDeduction.amount,
              ),
              slider:
                parseFloat(discountStore.paymentData.totalDeduction.amount) -
                parseFloat(discountStore.paymentData.deduction.amount),
              order_slider: 'GO',
            }),
        ...(experiments['experiment.serviceFeePriceGroup24'] ===
        'newPriceGrid24'
          ? { newPriceGrid24: true }
          : {}),
        ...(experiments['experiment.fakeBytelMobilePlan'] ===
        'fakeBytelMobilePlan'
          ? { fakeBytelMobilePlan: true }
          : {}),
      },
    })

    if (swapStore.hasOffer) {
      await $httpFetch(postOrder, {
        body: {
          payment_id: paymentResponse.paymentId,
          listing_id: swapStore.offer?.sourcingListing,
          shippingId: swapStore.offer?.defaultShippingModeId,
        },
      })
    }

    return paymentResponse
  } catch (error) {
    handleMobilePlanErrors(error as Error)
    handlePaymentErrors(error as HttpApiError)

    throw error
  }
}

const handleMethodChange = async (
  method: PaymentMethod | null,
  isSelectedByUser: boolean,
) => {
  errorMessage.value = null

  if (isSelectedByUser) {
    tracking.trackClick({
      name: `4-Payment_list-${method?.bmCode}`,
      zone: 'funnel',
    })
  }

  try {
    if (method) {
      await discountStore.setPaymentMethod(method)
    }
  } catch (error) {
    await handleUnauthorizedUser(error as Record<string, unknown>)
  }
}

const handleSubmitStart = ({
  customLoadingMessage,
  paymentMethod,
}: SubmitStartEvent) => {
  errorMessage.value = null
  loaderStore.enable({
    message: customLoadingMessage || i18n(translations.loadingPayment),
  })
  tracking.trackClick({
    value: paymentMethod?.bmCode || '',
    name: '4-Payment_cta-pay',
    zone: 'funnel',
  })
}

const showErrorMessage = async (
  message: PaymentReadableMessage | undefined,
) => {
  if (message) {
    errorMessage.value = message
    errorMessageBlock.value?.scrollIntoView({ behavior: 'smooth' })
  }
}

const handleSetupError = async ({ message }: SetupErrorEvent) => {
  await showErrorMessage(message)
}

const handleSubmitSuccess = ({ redirection }: SubmitSuccessData) => {
  cartStore.setShowCatchupModal(true)
  bouyguesStore.clear()

  proceedRedirection(redirection)
}

const handleSubmitError = async ({
  redirection,
  message,
}: SubmitErrorEvent) => {
  if (redirection) {
    proceedRedirection(redirection)

    return
  }

  loaderStore.disable()
  await showErrorMessage(message)
}
</script>
